/*@import url("https://cdn.syncfusion.com/ej2/bootstrap5.css");*/

@import '../src/styles/assets/css/imports/tabs.css';
/*@import "../src/styles/assets/css/imports/buttons.css";*/
@import "../src/styles/assets/css/imports/forms.css";
@import "../src/styles/assets/css/imports/spinner.css";

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.disclaimer {
    font-size: .80rem;
    color: #555;
}

label{
    font-weight:600;
    font-size:1rem;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

hr{
    margin-top:-0.8rem;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  text-align: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-controls-stacked {
    display: flex;
    flex-direction: row;
}

.pw-table thead tr:nth-child(1) {
    background-color: #5f5f5f;
    color: white;

}

.table th {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.table thead tr {
    border-bottom: 2px solid #ccc;
}

element.style {
}

.table thead th {
    font-size: 1.125rem;
    font-family: Univia-Pro,-system-ui,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif;
    font-weight: 700;
}

.pw-table th {
    text-align: center;
}

.pw-table th, .pw-table td {
 
}

/*body {
    margin: 0;
    font-family: roboto,"Helvetica Neue",Arial,sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: #333;
    background-color: #fff;
}*/
/*
.Container fluid {
    width: 100%;
    padding-right: 95px;
    padding-left: 95px;
    margin-right: 20px;
    margin-left: 20px;
    position: absolute;
}*/


h6 {
    font-family: Univia-Pro,-system-ui,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif;
    font-weight: 700;
    line-height: 1.75rem;
    color: #0039c1;
}



.installation-conditions {

    flex-wrap: wrap;
}

th {
    text-align: center;

}

.field-required {
    color: red;
}

.custom-controls-stacking {
    display: flex;
    flex-direction: row;
}

/*ul {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -moz-column-gap: 50px;
    column-gap: 100px;
}*/

.download-link {
    font-size: large;
    font-weight: bold;
}


/*.img {
    max-width: 100%;
}

img {

    width: 100px;
    max-height: 100px;

    text-align: center;
        
}

img {
    padding: 5px;
}*/