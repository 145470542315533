.dot {
    height: 25px;
    width: 25px;
    background-color: #707070;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    font-weight: 700;
    color: white;
}

.strip {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.transmitterStripImg {
    width: 60px;
    margin-top:4rem;
    margin-left:0.75rem;
    position:relative;
    z-index:3
}

.transmitterStripWire {
    height: 2.5px;
    width: 2rem;
    margin-left: -0.3rem;
    background-color: black;
    position: absolute;
    margin-top: 5.8rem;
    z-index: 1
    /*78BE21 - green*/
    /*05C3DD - cyan*/
    /*0039C1 - blue*/
}

.removeBtn {
    height: 20px;
    font-size: 8px;
    margin-left: 1.25rem;
    margin-top: 0.5rem;
    padding-right: 5px;
    padding-left: 5px;
    padding-top: 3px;
}

.dotAlignment {
    margin: 1.5rem;
    margin-top: 5rem;
}

