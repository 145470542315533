body {
}

.installation-conditions {
}

h5 {
    margin-bottom: 1rem;
    font-family: Univia-Pro,-system-ui,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.75rem;
    color: #0039c1;
}

.e-listbox-wrapper {
    height: 19.3rem
}

.installation-conditions {
    flex-wrap: wrap;
}

th {
    text-align: center;
}

.field-required {
    color: red;
}

.custom-controls-stacking {
    display: flex;
    flex-direction: row;
}

.applicationsTable {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -moz-column-gap: 50px; /* Mozilla Firefox */
    column-gap: 100px;
}

.applicationsTableTitleLabel {
    padding-top: 5%;
    padding-bottom: 5%;
    float: left;
    display: inline-block;
    color: #6D6E71;
}

.applicationsTableMaxLength {
    color: forestgreen;
    float: right;
    padding-top: 8.5%;
    padding-bottom: 5%;
    display: inline-block;
    padding-right:20%;
}

.legend {
    background-color: #f4f4f4;
    border: 1px solid #777;
    margin: 2rem 0 0;
    position: relative
}

    .legend h6 {
        background-color: #fff;
        padding: 5px 10px;
        position: absolute;
        top: -20px
    }

    .dot, .legend h6 {
        display: inline-block
    }

.dot {
    background-color: #707070;
    border-radius: 50%;
    color: #fff;
    font-weight: 700;
    height: 25px;
    text-align: center;
    width: 25px
}

.mm-app-chart {
    width: 100%;
    text-align: left;
    padding-left: 1.5%;
    padding-top: 1%;
    padding-bottom: 2%;
    background-color: white;
}
