@charset "utf-8";
/* CSS Document */

/* Form elements */
.form-control {
  display: block;
  width: 100%;
  padding: .65625em .65625em;
  font-size: 1em;
  font-family: var(--ff-bdy);
  font-weight: 400;
  line-height: 1.25;
  color: #333;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border-radius: 0;
  transition: border-color ease-in-out .25s
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #333;
  background-color: #fff;
  border-color: var(--primary);
  outline: 0;
}
.form-control::placeholder {
  color: #ccc;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #f4f4f4;
  opacity: .5;
}
select.form-control:not([size]):not([multiple]) {
  height: 40px;
}
select.form-control:focus::-ms-value {
  color: #333;
  background-color: #fff;
}
.form-control-file, .form-control-range {
  display: block
}
label {
  font-family: var(--ff-bdy);
  font-weight: 600;
  font-size: 1rem
}
.custom-select {
  display: inline-block;
  max-width: 100%;
  width: 100%;
  height: 40px;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.2;
  color: #333;
  vertical-align: middle;
  background: #fff url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0OCIgaGVpZ2h0PSI0OCIgdmlld0JveD0iMCAwIDQ4IDQ4Ij48cGF0aCBmaWxsPSIjN2Y3ZjdmIiBkPSJNMTQuODMgMTYuNDJMMjQgMjUuNTlsOS4xNy05LjE3TDM2IDE5LjI1bC0xMiAxMi0xMi0xMnoiLz48L3N2Zz4=) no-repeat right center;
  background-size: auto;
  background-clip: border-box;
  background-clip: padding-box;
  -webkit-background-size: 27px 27px;
  background-size: 27px 27px;
  border: 1px solid #ccc;
  border-radius: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.input-group-append {
    margin-left: -1px;
    border: 1px solid #cccccc;
}
.form-overflow{
    overflow:hidden;
}
form a {
    font-family: var(--ff-ss);
}
.linkfont {
    font-family: 'Roboto', sans-serif;
}
.exampleInputPassword {
    background-image: url("../../img/icons/password-icon.svg");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: 16px 18px;
    background-position: 98% 50%;
}
/*.custom-file {
    position: relative;
    display: inline-block;
    max-width: 100%;
    height: 40px;
    margin-bottom: 0;
}
.custom-file-input {
    min-width: 14rem;
    max-width: 100%;
    height: 40px;
    margin: 0;
    opacity: 0;
}
.custom-file-control {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 5;
    height: 40px;
    padding: 11px 15px;
    overflow: hidden;
    line-height: 1.2;
    color: #333333;
    pointer-events: none;
    user-select: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #CCCCCC;
    border-radius: 0;
}
.custom-file-control:lang(en)::before {
    content: "Browse";
}
.custom-file-control:lang(en):empty::after {
    content: "Choose file...";
}
.custom-file-control::before {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 6;
    display: block;
    height: 40px;
    padding: 11px 15px;
    line-height: 1.2;
    color: #333333;
    background-color: #F0F6F8;
    border-left: 1px solid #CCCCCC;
}
.cc-example button, .cc-example input {
    overflow: visible;
}*/
.custom-controls-stacked {
    display: flex;
    flex-direction: column;
}
.form-check {
    position: relative;
    display: block;
    margin-bottom: 0.5rem;
}
.custom-controls-stacked .custom-control {
    margin-bottom: 1rem;
}
.custom-control {
    position: relative;
    display: inline-flex;
    min-height: 1.5rem;
    padding-left: 2rem;
    margin-right: 1rem;
}
.custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.custom-radio .custom-control-input:checked ~ .custom-control-indicator {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23FFFFFF' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}
.custom-control-input:checked ~ .custom-control-indicator {
    color: #fff;
    background-color: #0039C1;
    border: 1px solid #0039C1;
}
.custom-radio .custom-control-indicator {
    border-radius: 50%;
}
.custom-control-indicator {
    position: absolute;
    top: 0rem;
    left: 0;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    pointer-events: none;
    user-select: none;
    border: 1px solid #CCCCCC;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
}
.custom-control-input:disabled ~ .custom-control-indicator {
    background-color: #F4F4F4;
    border: none;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-indicator {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23FFFFFF' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}
.cc-example .custom-control-input:active ~ .custom-control-indicator {
    color: #fff;
    /*background-color: #0039C1;*/
}
.exampleFormControlFile{
    width:100%;
}
/******multi select******/
.select2-container--default {
    background: #fff url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0OCIgaGVpZ2h0PSI0OCIgdmlld0JveD0iMCAwIDQ4IDQ4Ij48cGF0aCBmaWxsPSIjN2Y3ZjdmIiBkPSJNMTQuODMgMTYuNDJMMjQgMjUuNTlsOS4xNy05LjE3TDM2IDE5LjI1bC0xMiAxMi0xMi0xMnoiLz48L3N2Zz4=) no-repeat right center;
}
.select2-container {
    width: 100%;
    min-width: 100%;
}

.select2-results__option {
    padding-right: 20px;
    vertical-align: middle;
}
.select2-results__option:hover {
    background-color: #0039C1 !important;
}

.select2-results__option:before {
    content: "";
    display: inline-block;
    position: relative;
    height: 20px;
    width: 20px;
    border: 2px solid #cccccc;
    background-color: #fff;
    margin-right: 20px;
    vertical-align: middle;

}

.select2-results__option[aria-selected=true]:before {
    color: #fff;
    background-color: #0039C1;
    border: 2px solid #0039C1;
    display: inline-block;
    padding-left: 3px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23FFFFFF' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
    background-size: 84%;
    background-position: center;
    background-repeat: no-repeat;
}

.select2-container--default .select2-results__option[aria-selected=true] {
    background-color: transparent;
    color:#333333;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered li{
    display:flex;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #0039C1;
    color: #FFFFFF;
}

.select2-container--default .select2-selection--multiple {
    margin-bottom: 10px;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple, .select2-container--default .select2-selection--multiple {
    border-radius: 0px;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
    border-color: #0039C1;
}

.select2-container--default .select2-selection--multiple {
}

.select2-container--open .select2-dropdown--below {
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
}

.select2-selection .select2-selection--multiple:after {
    content: 'hhghgh';
}
/* select with icons badges single*/
.select-icon .select2-selection__placeholder .badge {
    display: none;
}

.select-icon .placeholder {
    display: none;
}

.select-icon .select2-results__option:before,
.select-icon .select2-results__option[aria-selected=true]:before {
    display: none !important;
    /* content: "" !important; */
}

.select-icon .select2-search--dropdown {
    display: none;
}
/******End of multi select******/
.callout {
    padding: 20px;
    margin: 20px 0;
    border-left-style: solid;
    border-left-width: 5px;
}
.callout-warning {
    border-left-color: #FFC20E;
    background-color: #FFFCF4;
}
.callout-warning h5 {
    color: #FFC20E;
    font-weight:normal !important;
}
.form-control-lg{
    height:50px !important;
    padding:0px 15px
}
.form-control-default {
    padding: 0px 15px
}
.form-control-sm {
    height: 30px !important;
    font-size: 14px;
    padding: 0px 15px;
}
.optional {
    color: #858585;
    font-weight:400;
}
.help-block {
    color: #858585;
}