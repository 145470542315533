@charset "utf-8";
/* CSS Document */


@keyframes spinner-border {
    to

{
    transform: rotate(360deg);
}

}

.spinner-border {
    display: inline-block;
    width: 90px;
    height: 90px;
    vertical-align: text-bottom;
    border: 7px solid currentColor;
    border-right-color: #ccc;
    border-radius: 50%;
    animation: spinner-border .75s linear infinite;
}

.spinner-md {
    width: 24px;
    height: 24px;
    border-width:4px ;
}
.spinner-sm {
    width: 16px;
    height: 16px;
    border-width: 3px;
}
@keyframes spinner-grow {
    0%

{
    transform: scale(0);
}

50% {
    opacity: 1;
}

}

.spinner-grow {
    display: inline-block;
    width: 40px;
    height: 40px;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    animation: spinner-grow .75s linear infinite;
}

.spinner-grow-sm {
    width: 40px;
    height: 40px;
}
