body {
}

.moduleImg {
    text-align: center;
    
    width:90px;
    margin:0.25rem;
}

.patchCordImg {
    text-align: center;
    
    width: 90px;
    margin: 0.25rem;
}
