/* CSS Document */
.nav-tabs {
    /*border-bottom: 3px solid #0039C1;*/
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active, .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    background-color: #fff;
    color: #0039c1;
    border: 1px solid #ccc;
    border-bottom: 1px solid transparent;
    position:relative;
}
.nav-tabs .nav-link:hover::after, .nav-tabs .nav-link.active:after{
    content: '';
    height: 5px;
    position: absolute;
    top: 0;
    width: 100%;
    border-top: 5px solid #0039c1;
    left:0px;
}

.nav-link {
    padding: 10px 20px;
    color: #333333;
    
}
.tabs .nav-item {
    padding: 0;
    margin-right: 10px;
    position:relative;
}
.tabs .nav-link {
    padding: 10px 20px;
    color: #333333;
    transition: all 300ms ease;
    border: 1px solid #ccc;
    color: #333;
    background: #f4f4f4;
    border-radius: 0px;
}
.tab-content > .tab-pane {
    background-color: #FFFFFF;
    box-shadow: 0px 5px 6px #00000029;
    border-bottom: 3px solid #0039C1;
    padding: 20px;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;

}
.nav-tabs .nav-link.disabled{
    opacity:.5;
}
.nav-tabs .nav-link.disabled:hover, .nav-tabs .nav-link.disabled:focus, .nav-tabs .nav-link.disabled:active {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent;
}
.disabled{
    cursor:not-allowed;
}
.disabled:after, .disabled:hover {
    border-top-color:transparent !important;
}



@media (min-width: 992px) {
}
@media (max-width: 767px) {
    .nav-tabs {
        /*border-bottom: 3px solid #0039C1;*/
        width: 100%;
        overflow-x: scroll;
        display: inline-flex;
        flex-wrap: nowrap;
        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none;
    }
    .nav-tabs::-webkit-scrollbar{
        display: none;
    }
    .nav-tabs .nav-link{
        width:auto;
        min-width: max-content;
    }
}




