.new-ui ol.breadcrumb.breadcrumb-inverse li.breadcrumb-item:last-of-type a {
    font-weight: 400 !important;
}

/*.new-ui ol.breadcrumb {
            font-family: UniviaPro !important;
            font-weight: 200 !important;
        }*/

.breadcrumb > li + li::before {
    content: none;
}

.breadcrumb {
    background-color: transparent;
}

    .breadcrumb > li {
        text-shadow: 0 0px 0 #ffffff;
        /*font-family: UniviaPro;*/
    }

a {
    text-decoration: none;
}

.h1, h1 {
    margin-bottom: 1.5rem;
    font-family: Univia-Pro,-system-ui,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif;
    font-size: 3rem;
    font-weight: 300;
    line-height: 3.375rem;
    color: inherit;
}

.new-ui ol.breadcrumb {
    font-family: Univia-Pro,-system-ui,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif;
    font-size: .877rem;
    list-style-type: none;
    margin: 0 0 30px;
    padding: 0;
}